<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="primary"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page">
          <VuesticLogo />
        </RouterLink>
      </div>
    </template>
    <template #right>
      <VaButtonDropdown
        class="mr-2 mt-2"
        split
        :disable-button="disableButton"
        :disable-dropdown="disableDropdown"
        label="Sync"
        round
        @mainButtonClick="onMasterSyncClick"
      >
        <VaProgressCircle indeterminate>
          {{ overallProgress + '%' }}
        </VaProgressCircle>

        <div v-for="module in modules" :key="module.name">
          <p>{{ module.label }}: {{ module.progress }}%</p>
          <VaProgressBar size="small" :model-value="module.progress" />
        </div>
      </VaButtonDropdown>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import VuesticLogo from '../VuesticLogo.vue'
import { ref, onMounted } from 'vue'
import { dashboardService } from '../../api/dashboardService'
import { useToast } from 'vuestic-ui/web-components'

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()
const { init: notify } = useToast()
const disableButton = ref(false)
const disableDropdown = ref(false)
const overallProgress = ref(0)

const modules = [
  { name: 'customers', label: 'Customers', progress: 0 },
  { name: 'vendors', label: 'Vendors', progress: 0 },
  { name: 'projects', label: 'Projects', progress: 0 },
  { name: 'users', label: 'Users', progress: 0 },
  { name: 'invoices', label: 'Invoices', progress: 0 },
  { name: 'bills', label: 'Bills', progress: 0 },
]

const { isSidebarMinimized } = storeToRefs(GlobalStore)

onMounted(async () => {
  disableDropdown.value = true
  await fetchProgress(false)
})

const fetchProgress = async (isFromStartSync: boolean) => {
  // console.log("onMasterSyncClick");

  const progressData = await dashboardService.getMasterSyncProgress()

  if (!progressData.isActive && !isFromStartSync) {
    disableButton.value = false
    disableDropdown.value = true
    if (overallProgress.value == 100) {
      notify({
        message: `Synchronization has completed`,
        color: 'success',
      })
    }
    return
  } else {
    disableButton.value = true
    disableDropdown.value = false
  }

  modules.forEach((module) => {
    if (module.name === 'customers') {
      module.progress = progressData.customers || 0
    } else if (module.name === 'projects') {
      module.progress = progressData.projects || 0
    } else if (module.name === 'users') {
      module.progress = progressData.users || 0
    } else if (module.name === 'invoices') {
      module.progress = progressData.invoices || 0
    } else if (module.name === 'bills') {
      module.progress = progressData.bills || 0
    } else if (module.name === 'vendors') {
      module.progress = progressData.vendors || 0
    }
  })

  // Update overall progress
  overallProgress.value = parseInt(progressData.overall.toFixed(2)) || 0

  // Continue fetching until the overall progress reaches 100%
  if (overallProgress.value < 100) {
    setTimeout(() => fetchProgress(false), 4000) // Fetch every second
  }

  if (overallProgress.value == 100) {
    disableButton.value = false
    disableDropdown.value = true
    notify({
      message: `Synchronization has completed`,
      color: 'success',
    })
  }
}

const onMasterSyncClick = async () => {
  // console.log("onMasterSyncClick");

  disableButton.value = true

  disableDropdown.value = false

  notify({
    message: `Synchronize has been started`,
    color: 'success',
  })

  await dashboardService.startMasterSync()
  await fetchProgress(true)
}
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
